// 初始化 路由规则
export const initRouter = [
  {
    path: '*/login',
    // alias: '*/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/home',
    component: () => import('@/views/home/deliverDashboard'),
    hidden: true
  }
]

// 动态路由加载库
export const componentsMap = {
  deliverDashboard:           () => import('@/views/home/deliverDashboard'),                //
  dashboard:           () => import('@/views/home/deliverDashboard'),                //首页
 
  // 供应商模块
  reportAudit:     () => import('@/views/supplier/reportAudit'), //批次报告审核
  addReport:     () => import('@/views/supplier/reportAudit/addReport'), //批次报告审核新增
  reportDetail:     () => import('@/views/supplier/reportAudit/reportDetail'), //批次报告审核详情
  purchaseOrder:     () => import('@/views/supplier/purchaseOrder'), // 采购单发货批次上传
  shipmentBatchetail:     () => import('@/views/supplier/purchaseOrder/shipmentBatchetail'), // 采购单发货批次上传详情
  batchDelivery:     () => import('@/views/supplier/batchDelivery'), //  批量送仓
  batchDeliveryDetails:     () => import('@/views/supplier/batchDelivery/components/overOrder/detail'), //  批量送仓
  overAsn:     () => import('@/views/supplier/overAsn'), //  按单越库
  overAsnByPurchase:     () => import('@/views/supplier/overAsn/components/overOrder/detail'), //  按单越库-采购单详情
  overAsnBySku:     () => import('@/views/supplier/overAsn/components/overSku/detail'), //  按单越库-sku详情
  supplierStoreDeliver:     () => import('@/views/supplier/supplierStoreDeliver'), //  门店直送
  // supplierStoreDeliverByPurchase:     () => import('@/views/supplier/supplierStoreDeliver/components/supplierOrder/detail'), //  门店直送-采购单详情
  supplierStoreDeliverBySku:     () => import('@/views/supplier/supplierStoreDeliver/components/overOrder/detail'), //  门店直送-sku详情
  batchPurchase:     () => import('@/views/supplier/batchPurchase'), //  门店直送-sku详情
  batchPurchaseDetails:     () => import('@/views/supplier/batchPurchase/batchPurchaseDetails'), //  门店直送-sku详情
  // batchPurchaseDetailsGift:     () => import('@/views/supplier/batchPurchase/batchPurchaseDetailsGift'), //  门店直送-sku详情
  batchReportAudit:     () => import('@/views/supplier/batchReportAudit'), //  门店直送-sku详情
  batchReportDetail:     () => import('@/views/supplier/batchReportAudit/batchReportDetail'), //  门店直送-sku详情
  supplier_inventory:     () => import('@/views/supplier/supplierInventory'), //  实时库存申报
  supplier_quotation:     () => import('@/views/supplier/supplierQuotation'), //  供应商主动报价
  supplier_quotation_detail:     () => import('@/views/supplier/supplierQuotation/supplierQuotationDetail'), //  供应商主动报价新增
  purchase_supplier_sp_adyk:     () => import('@/views/supplier/purchaseSupplierSpAdyk'), //   按单越库采购单 
  purchase_supplier_sp_adyk_detail:     () => import('@/views/supplier/purchaseSupplierSpAdyk/purchaseSupplierSpAdykDetail'), //   按单越库采购单详情
  purchase_sp_supplier_mdzs:     () => import('@/views/supplier/purchaseSpSupplierMdzs'), //   门店直送采购单 
  purchase_supplier_sp_mdzs_detail:     () => import('@/views/supplier/purchaseSpSupplierMdzs/purchaseSupplierSpMdzsDetail'), //   门店直送采购单详情
  supplier_qualification_report:     () => import('@/views/supplier/supplierQualificationReport'), //   资质文件上传
  supplier_purchase_statistics:     () => import('@/views/supplier/supplierPurchaseStatistics'), //   综合采购统计
  supplier_purchase_statistics_detail:     () => import('@/views/supplier/supplierPurchaseStatistics/supplierPurchaseStatisticsDetail'), //   综合采购统计详情
  supplierRouteLabel:     () => import('@/views/supplier/supplierRouteLabel'), //   供应商路线标记
  supplierQuotePriceCalendar:     () => import('@/views/supplier/supplierQuotePriceCalendar'), //   定期报价日历
  supplierQuotePriceCalendarDetail:     () => import('@/views/supplier/supplierQuotePriceCalendar/supplierQuotePriceCalendarDetail'), //   定期报价日历详情
  supplierSku:     () => import('@/views/supplier/supplierSku'), //   可供货SKU
  supplierShop:     () => import('@/views/supplier/supplierShop'), //   可供货门店
  supplierRoute:     () => import('@/views/supplier/supplierRoute'), //   可送货路线

}